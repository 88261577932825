import Link from 'next/link';
import { Button, Card, Col, Container, Row } from 'react-bootstrap';

import DynamicSlider from 'components/DynamicSlider/DynamicSlider';
import GradientCard from 'components/GradientCard/GradientCard';
import StandardDrawGradientCardWithImage from 'components/StandardDrawGradientCardWithImage/StandardDrawGradientCardWithImage';
import { IDraw } from 'models/IDraw';
import { IPersona } from 'models/IPersona';

import s from './HomeDrawSlider.module.scss';

type CustomCampaignProps = {
  is_enabled: boolean;
  name: string;
  image_url: string;
  disable_cc_banner: boolean;
};

interface IProps {
  draws: IDraw[];
  persona?: IPersona;
  custom_campaign?: CustomCampaignProps;
}

const SLIDER_SETTING = {
  dots: true,
  infinite: true,
  pauseOnFocus: true,
  pauseOnHover: true,
  speed: 500,
  autoplaySpeed: 5000,
  autoplay: true,
  slidesToShow: 1,
  slidesToScroll: 1,
};

type SliderItemProps = {
  id: string;
  image_url: string;
  image_name: string;
  cta?: {
    description: string;
    label: string;
    url: string;
    image: JSX.Element;
  };
};

const getCustomSliderItem = ({
  id,
  image_url,
  image_name,
  cta,
}: SliderItemProps) => {
  return (
    <div key={id}>
      <div className="position-relative flex-grow-1">
        <img
          src={image_url}
          alt={image_name}
          className="object-fit-cover w-100 h-100 position-absolute top-0 left-0 d-block"
        />
      </div>
      {cta && (
        <Container className="mt-n6 flex-grow-0 flex-shrink-0">
          <GradientCard>
            <Card.Body className="p-6 d-flex">
              <Row className="justify-content-between">
                <Col xs={12} md={6} lg={8}>
                  <h4 className="h1 m-0 text-white text-center text-md-left">
                    {cta.description}
                  </h4>
                </Col>

                <Col
                  xs={12}
                  md={5}
                  lg={4}
                  xl={3}
                  className="flex-grow-0 mt-6 mt-md-0"
                >
                  <Link href={cta.url} prefetch={false}>
                    <Button as="a" variant="danger d-block">
                      {cta.label}
                    </Button>
                  </Link>

                  {cta.image}
                </Col>
              </Row>
            </Card.Body>
          </GradientCard>
        </Container>
      )}
    </div>
  );
};

const HomeDrawSlider = ({ draws, persona, custom_campaign }: IProps) => {
  return (
    <DynamicSlider className={s.homeDrawSlider} {...SLIDER_SETTING}>
      {draws.map(draw => (
        <div key={draw.id}>
          {!!draw.draw_hero_images?.[0]?.image?.url && (
            <div className="position-relative flex-grow-1">
              <img
                src={draw.draw_hero_images[0].image.url}
                alt={draw.heading}
                className="object-fit-cover w-100 h-100 position-absolute top-0 left-0 d-block"
              />
            </div>
          )}

          <Container className="mt-n6 flex-grow-0 flex-shrink-0">
            <StandardDrawGradientCardWithImage draw={draw} />
          </Container>
        </div>
      ))}
      {persona?.cc_page_sub_hero_image_asset?.media_file?.url &&
        !custom_campaign?.disable_cc_banner &&
        getCustomSliderItem({
          id: `${persona.id}`,
          image_url: persona.cc_page_sub_hero_image_asset.media_file.url,
          image_name: persona.cc_page_sub_hero_image_asset.name,
          cta: {
            description:
              'Sign up to the Champions club and unlock over $1.25 Million in exclusive prizes',
            label: 'Subscribe Today',
            url: '/subscribe',
            image: (
              <img
                loading="lazy"
                src="/imgs/championsClubBadge-negative.svg"
                alt="Champions Club Badge"
                className="d-block mt-4 px-3 mx-auto"
              />
            ),
          },
        })}

      {custom_campaign?.is_enabled &&
        custom_campaign?.image_url &&
        getCustomSliderItem({
          id: 'custom-campaign',
          image_url: custom_campaign.image_url,
          image_name: custom_campaign.name || 'custom campaign',
        })}
    </DynamicSlider>
  );
};

export default HomeDrawSlider;
